<mat-toolbar
  color="primary"
  *ngIf="!isScanner && (isLoggedIn$ | async) && showNav"
>
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="drawer.toggle()"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <img
    src="assets/logoFooterFAB.webp"
    alt="FAB"
    style="height: 35px; padding-left: 10px"
  />
  <span class="spacer"></span>
  <label style="overflow: hidden">{{ loggedUserName }}</label>
  <span *ngIf="useLegajo$" style="margin-left: 10px">
    ({{ (legajoId$ | async) || "Staff" }})
  </span>
  <button
    title="Cerrar Sesión"
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="logout()"
  >
    <mat-icon aria-label="Side nav toggle icon">logout</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    opened
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="
      (!(isLoggedIn$ | async) || (isHandset$ | async)) === false &&
      !isScanner &&
      showNav
    "
  >
    <mat-nav-list style="background-color: white">
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        href="student-courses"
        ><mat-icon>book</mat-icon> Mis Cursos</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        href="person-receipts-list"
        ><mat-icon>receipt</mat-icon> Mis Comprobantes</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        href="user-account"
        ><mat-icon>account_circle</mat-icon> Mis Datos</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        href="payments-information"
        ><mat-icon>credit_card</mat-icon> Medios de Pago</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        href="contact-information"
        ><mat-icon>phone</mat-icon> Datos de contacto</a
      >
      <mat-divider
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
      ></mat-divider>
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([userProfiles.ADMIN]) ||
          ((useCredential$ | async) && showMenuByProfile([userProfiles.USER]))
        "
        href="user-credential"
        target="_blank"
        ><mat-icon>contact_mail</mat-icon> Credenciales</a
      >
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([userProfiles.ADMIN]) ||
          ((useLicense$ | async) && showMenuByProfile([userProfiles.USER]))
        "
        href="user-license"
        target="_blank"
        ><mat-icon>sports_mma</mat-icon> Licencias</a
      >
      <mat-divider
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL
          ])
        "
      ></mat-divider>
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL,
            userProfiles.SECRETARY,
            userProfiles.CREDENTIAL
          ])
        "
        href="students-list"
        ><mat-icon>people</mat-icon> Usuarios</a
      >
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.SCHOOL,
            userProfiles.SECRETARY
          ])
        "
        href="change-profile-requests"
        ><mat-icon>how_to_reg</mat-icon> Solicitudes</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SCHOOL])"
        href="courses-list"
        ><mat-icon>book</mat-icon> Cursos</a
      >
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.TREASURY])"
        href="payment-receipts-list"
        ><mat-icon>receipt</mat-icon> Comprobantes</a
      >
      <mat-divider
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SECRETARY])"
      ></mat-divider>
      <a
        mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SECRETARY])"
        href="credential-type-manager"
        ><mat-icon>build</mat-icon> Gestión de cargos</a
      >
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.SECRETARY,
            userProfiles.LICENSE
          ])
        "
        href="licenses-list"
        ><mat-icon>assignment</mat-icon> Gestión de Licencias</a
      >
      <mat-divider
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL
          ])
        "
      ></mat-divider>
      <a
        mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL
          ])
        "
        href="courses-report"
        ><mat-icon>summarize</mat-icon> Reporte por curso</a
      >
    </mat-nav-list>
    <!-- <mat-card class="closed-enrollments-alert">
      <mat-icon class="closed-enrollments-alert-icon">notification_important</mat-icon> A partir del mes de <b>AGOSTO</b> sera necesario tener actualizada la contraseña de tu cuenta.En los proximos dias te estaremos enviando un correo con los pasos a seguir.
    </mat-card> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
