import { Injectable } from '@angular/core';
import { Credential } from "src/app/models/credential";
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { HttpBaseResponse } from '../models/base-response';

@Injectable({
  providedIn: 'root'
})
export class ApiCredentialsService extends ApiBaseService {

  constructor(private http: HttpClient) {
    super();
    this._controller = "credentials";
  }

  /**
    * @ngdoc function
    * @name getAll
    * @param personId number
    * @methodOf ApiCredentialsService
    * @description Se obtiene la información de las credenciales.
    * @return HttpBaseResponse
    */

  getAll(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getTypes() {
    let endpoint = `${this._apiUrl}/${this._controller}/types`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  save(credentialData: Credential) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/form`, credentialData);
  }

}
