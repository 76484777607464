// import { MatChipsModule } from '@angular/material/chips';
// import { MatListModule } from '@angular/material/list';
// import { MatRadioModule } from '@angular/material/radio';
// import { MatCardModule } from '@angular/material/card';
// import { MatButtonModule } from '@angular/material/button';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatTableModule } from '@angular/material/table';
// import { MatSortModule } from '@angular/material/sort';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatCheckboxModule } from "@angular/material/checkbox";
// import { MatInputModule } from '@angular/material/input';
// import {MatFormFieldModule} from '@angular/material/form-field';

// ! UPDATE ANGULAR 13 TO 15
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
// import { MatLegacySortModule as MatSortModule } from "@angular/material/legacy-sort";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";

import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { CommonModule } from '@angular/common';

import { BrowserModule } from "@angular/platform-browser";
import { MatIconModule } from "@angular/material/icon";
import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";
import { FormsModule } from "@angular/forms";
// import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';

// PrimeNG
import { ButtonModule } from "primeng/button";
import { CarouselModule } from "primeng/carousel";
import { TagModule } from "primeng/tag";
import { CardModule } from "primeng/card";
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { ImageModule } from 'primeng/image';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';


// App components
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { CustomTableComponent } from "./components/custom-table/custom-table.component";
import { CoursesListComponent } from "./pages/courses-list/courses-list.component";
import { StudentsListComponent } from "./pages/students-list/students-list.component";
import { LicenseListTableComponent } from "./components/license-list-table/license-list-table.component";
import { LicenseListComponent } from "./pages/licenses-list/licenses-list.component";
import { LicenseDialogComponent } from "./components/license-dialog/license-dialog.component";
import { LicenseCreateDialogComponent } from "./components/license-create-dialog/license-create-dialog.component";
import { LicenseFormComponent } from "./pages/license-form/license-form.component";
import { StudentCoursesComponent } from "./pages/student-courses/student-courses.component";
import { StudentCourseDetailsComponent } from "./pages/student-course-details/student-course-details.component";
import { AddPaymentReceiptComponent } from "./pages/add-payment-receipt/add-payment-receipt.component";
import { PaymentReceiptsListComponent } from "./pages/payment-receipts-list/payment-receipts-list.component";
import { PaymentReceiptDetailsComponent } from "./pages/payment-receipt-details/payment-receipt-details.component";
import { CredentialTypeManagerComponent } from "./pages/credential-type-manager/credential-type-manager.component";
import { ChangeProfileRequestsComponent } from "./pages/change-profile-requests/change-profile-requests.component";
import { ChangeProfileRequestsDetailsComponent } from "./pages/change-profile-requests-details/change-profile-requests-details.component";
import { CoursesReportComponent } from "./pages/courses-report/courses-report.component";
import { CustomChartComponent } from "./components/custom-chart/custom-chart.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { LayoutModule } from "@angular/cdk/layout";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SectionHeaderComponent } from "./components/section-header/section-header.component";
import { FileButtonComponent } from "./components/file-button/file-button.component";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
import { PaymentFeesComponent } from "./components/payment-fees/payment-fees.component";
import { FeesHistoryTableComponent } from "./components/fees-history-table/fees-history-table.component";
import { CoursesFormComponent } from "./pages/courses-form/courses-form.component";
import { BackButtonComponent } from "./components/back-button/back-button.component";
import { RegistrationFormComponent } from "./components/registration-form/registration-form.component";
import { UserProfileFormComponent } from "./components/user-profile-form/user-profile-form.component";
import { StudentsFormComponent } from "./pages/students-form/students-form.component";
import { CourseDetailsDialogComponent } from "./components/course-details-dialog/course-details-dialog.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { EnrollmentDialogComponent } from "./components/enrollment-dialog/enrollment-dialog.component";
import { ImageDialogComponent } from "./components/image-dialog/image-dialog.component";
import { StudentsByStatusTableComponent } from "./components/students-by-status-table/students-by-status-table.component";
import { UserValidationComponent } from "./pages/user-validation/user-validation.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { PersonReceiptsComponent } from "./pages/person-receipts/person-receipts.component";
import { UserCredentialComponent } from "./pages/user-credential/user-credential.component";
import { UserLicenseComponent } from "./pages/user-license/user-license.component";
import { PaymentsInformationComponent } from "./pages/payments-information/payments-information.component";
import { ContactInformationComponent } from "./pages/contact-information/contact-information.component";
import { StudentFileComponent } from "./components/student-file/student-file.component";
import { StudentFileDialogComponent } from "./components/student-file-dialog/student-file-dialog.component";
import { UserAccountComponent } from "./pages/user-account/user-account.component";
import { ReceiptsByStatusTableComponent } from "./components/receipts-by-status-table/receipts-by-status-table.component";
import { CredentialComponent } from "./components/credential/credential.component";
import { CredentialVerticalComponent } from "./components/credential-vertical/credential-vertical.component";
import { CredentialHorizontalComponent } from "./components/credential-horizontal/credential-horizontal.component";
import { LicenseHorizontalComponent } from "./components/license-horizontal/license-horizontal.component";
import { CoursesListDialogComponent } from "./components/courses-list-dialog/courses-list-dialog.component";
import { CredentialDialogComponent } from "./components/credential-dialog/credential-dialog.component";
import { CredentialTypeManagerDialogComponent } from "./components/credential-type-manager-dialog/credential-type-manager-dialog.component";
import { ScannerComponent } from "./pages/scanner/scanner.component";
import {
  LottieComponent,
  provideLottieOptions,
  AnimationOptions,
  AnimationLoader,
} from "ngx-lottie";
import { LottiePlayer } from "lottie-web";
import { CustomLottieComponent } from "./components/lottie/lottie.component";
import { TestComponent } from "./components/test/test.component";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ScannerComponent,
    ForgotPasswordComponent,
    CoursesListComponent,
    StudentsListComponent,
    LicenseListTableComponent,
    LicenseListComponent,
    LicenseCreateDialogComponent,
    LicenseDialogComponent,
    LicenseHorizontalComponent,
    LicenseFormComponent,
    StudentCoursesComponent,
    StudentCourseDetailsComponent,
    AddPaymentReceiptComponent,
    PaymentReceiptsListComponent,
    PaymentReceiptDetailsComponent,
    ChangeProfileRequestsComponent,
    ChangeProfileRequestsDetailsComponent,
    CredentialTypeManagerComponent,
    CoursesReportComponent,
    RegistrationComponent,
    NavigationComponent,
    CustomTableComponent,
    SectionHeaderComponent,
    FileButtonComponent,
    PaymentFeesComponent,
    FeesHistoryTableComponent,
    CoursesFormComponent,
    BackButtonComponent,
    RegistrationFormComponent,
    UserProfileFormComponent,
    StudentsFormComponent,
    CourseDetailsDialogComponent,
    EnrollmentDialogComponent,
    ImageDialogComponent,
    StudentsByStatusTableComponent,
    UserValidationComponent,
    ChangePasswordComponent,
    AlertDialogComponent,
    PersonReceiptsComponent,
    UserCredentialComponent,
    UserLicenseComponent,
    PaymentsInformationComponent,
    ContactInformationComponent,
    StudentFileComponent,
    StudentFileDialogComponent,
    UserAccountComponent,
    ReceiptsByStatusTableComponent,
    CredentialComponent,
    CredentialVerticalComponent,
    CredentialHorizontalComponent,
    CoursesListDialogComponent,
    CredentialDialogComponent,
    CredentialTypeManagerDialogComponent,
    
  ],
  imports: [
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatPaginatorModule,
    MatListModule,
    MatTableModule,
    // MatSortModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    
    CommonModule,
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatDividerModule,
    MatToolbarModule,
    LayoutModule,
    MatSidenavModule,
    OverlayModule,
    HttpClientModule,
    MatExpansionModule,
    NgxScannerQrcodeModule,
    
    // Prime
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    ButtonModule,
    CarouselModule,
    TagModule,
    CardModule,
    DataViewModule,
    TabMenuModule,
    ImageModule,
    TableModule,
    FloatLabelModule,
    
    // custom
    TestComponent,
    CustomLottieComponent,
    CustomChartComponent,
    
  ],
  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ "lottie-web"),
    }),
    AnimationLoader,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],

})
export class AppModule {}
