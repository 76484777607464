import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { UserProfilesEnum } from "src/app/enums/user-profiles-enum";
import { AddPaymentReceiptComponent } from "./pages/add-payment-receipt/add-payment-receipt.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { CoursesFormComponent } from "./pages/courses-form/courses-form.component";
import { CoursesListComponent } from "./pages/courses-list/courses-list.component";
import { CoursesReportComponent } from "./pages/courses-report/courses-report.component";
import { CredentialTypeManagerComponent } from "./pages/credential-type-manager/credential-type-manager.component";
import { LicenseListComponent } from "./pages/licenses-list/licenses-list.component";
import { LicenseFormComponent } from "./pages/license-form/license-form.component";
import { ChangeProfileRequestsComponent } from "./pages/change-profile-requests/change-profile-requests.component";
import { ChangeProfileRequestsDetailsComponent } from "./pages/change-profile-requests-details/change-profile-requests-details.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { HomeComponent } from "./pages/home/home.component";
import { ScannerComponent } from "./pages/scanner/scanner.component";
import { LoginComponent } from "./pages/login/login.component";
import { PaymentReceiptDetailsComponent } from "./pages/payment-receipt-details/payment-receipt-details.component";
import { PaymentReceiptsListComponent } from "./pages/payment-receipts-list/payment-receipts-list.component";
import { UserCredentialComponent } from "./pages/user-credential/user-credential.component";
import { UserLicenseComponent } from "./pages/user-license/user-license.component";
import { PaymentsInformationComponent } from "./pages/payments-information/payments-information.component";
import { ContactInformationComponent } from "./pages/contact-information/contact-information.component";
import { PersonReceiptsComponent } from "./pages/person-receipts/person-receipts.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { StudentCourseDetailsComponent } from "./pages/student-course-details/student-course-details.component";
import { StudentCoursesComponent } from "./pages/student-courses/student-courses.component";
import { StudentsFormComponent } from "./pages/students-form/students-form.component";
import { StudentsListComponent } from "./pages/students-list/students-list.component";
import { UserAccountComponent } from "./pages/user-account/user-account.component";
import { UserValidationComponent } from "./pages/user-validation/user-validation.component";
import { TestView } from "./pages/test/test.view";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "registration", component: RegistrationComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "user-validation/:token", component: UserValidationComponent },
  { path: "change-password/:token", component: ChangePasswordComponent },
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "courses-list",
    component: CoursesListComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserProfilesEnum.ADMIN, UserProfilesEnum.SCHOOL],
    },
  },
  {
    path: "courses-form",
    component: CoursesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "courses-form/:courseId",
    component: CoursesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "courses-report",
    component: CoursesReportComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserProfilesEnum.ADMIN,
        UserProfilesEnum.SCHOOL,
        UserProfilesEnum.TREASURY,
      ],
    },
  },
  {
    path: "credential-type-manager",
    component: CredentialTypeManagerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserProfilesEnum.ADMIN, UserProfilesEnum.SECRETARY],
    },
  },
  {
    path: "change-profile-requests",
    component: ChangeProfileRequestsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserProfilesEnum.ADMIN,
        UserProfilesEnum.SCHOOL,
        UserProfilesEnum.SECRETARY,
      ],
    },
  },
  {
    path: "change-profile-requests/:changeRequestId",
    component: ChangeProfileRequestsDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "students-form",
    component: StudentsFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "students-form/:personId",
    component: StudentsFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "students-list",
    component: StudentsListComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserProfilesEnum.ADMIN,
        UserProfilesEnum.SCHOOL,
        UserProfilesEnum.TREASURY,
        UserProfilesEnum.SECRETARY,
        UserProfilesEnum.CREDENTIAL,
      ],
    },
  },
  {
    path: "licenses-list",
    component: LicenseListComponent,
    canActivate: [AuthGuard],
    data: {
      // roles: [UserProfilesEnum.TREASURY, UserProfilesEnum.SECRETARY],
      roles: [
        UserProfilesEnum.ADMIN,
        UserProfilesEnum.SECRETARY,
        UserProfilesEnum.LICENSE,
      ],
    },
  },
  {
    path: "licenses-form",
    component: LicenseFormComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserProfilesEnum.ADMIN,
        UserProfilesEnum.SECRETARY,
        UserProfilesEnum.LICENSE,
      ],
    },
  },
  {
    path: "student-courses",
    component: StudentCoursesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "student-course-details/:courseId",
    component: StudentCourseDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "student-course-details/:courseId/:studentId/:personId",
    component: StudentCourseDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-payment/:courseId",
    component: AddPaymentReceiptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-payment/:courseId/:personId",
    component: AddPaymentReceiptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payment-receipt-details/:receiptId",
    component: PaymentReceiptDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payment-receipts-list",
    component: PaymentReceiptsListComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserProfilesEnum.ADMIN, UserProfilesEnum.TREASURY],
    },
  },
  {
    path: "person-receipts-list",
    component: PersonReceiptsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-credential",
    component: UserCredentialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-license",
    component: UserLicenseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payments-information",
    component: PaymentsInformationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact-information",
    component: ContactInformationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-account",
    component: UserAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "scanner",
    component: ScannerComponent,
  },
  {
    path: "test",
    component: TestView,
    canActivate: [AuthGuard],
    data: {
      // roles: [UserProfilesEnum.DEVS],
    },
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
