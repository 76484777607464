export enum UserProfilesEnum {
  ADMIN = 1,
  TREASURY = 2,
  SCHOOL = 3,
  USER = 4,
  SECRETARY = 5,
  CREDENTIAL = 6,
  LICENSE = 7,
  RECORDS = 8,
  FISCAL = 9,
  DEVS = 99,
}
